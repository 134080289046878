import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';

import '../stylesheets/main.scss';

const Layout = ( props ) => {

    return (
        <>
            <Navbar expand="md" bg="primary" variant="dark">
                <Container>
                    <Link to="/">
                        <Navbar.Brand>
                            gender.pm
                        </Navbar.Brand>
                    </Link>
                    <Navbar.Collapse>
                        <Nav className="ml-auto">
                            <Nav.Link className="mx-2" as={Link} to="/blog">Posts</Nav.Link>
                            <Nav.Link className="mx-2" as={Link} to="/contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container>
                <Row>
                    <Col xs={12}>
                        { props.children }
                    </Col>
                </Row>
            </Container>

        </>
    )

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}
  

export default Layout;