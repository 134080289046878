import * as React from "react"

import Layout from '../components/layout';



// markup
const IndexPage = () => {
  return (
    <Layout>
      Hi there woooooo
    </Layout>
  )
}

export default IndexPage
